@import '../styles/mixins.scss';

.terminal-container {
  $button-background: rgba(255, 255, 255, 0.08);
  $placeholder-color: #777;

  @include dark-blue-blur;

  pointer-events: all;
  color: $light-grey;
  height: 100%;
  padding: 0 1.25rem;
  border-right: 1px solid $zaber-grey;
  display: flex;
  flex-direction: column;

  .title {
    padding: 1rem 0;
  }

  .buttons-container {
    padding: 1.25rem 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    button {
      border: 1px solid $light-grey;
      color: $light-grey;
      padding-left: 2.5rem;
      padding-right: 2.5rem;

      &:hover.clear {
        color: $white;
        background-color: $button-background;
        border-color: $white;
      }
    }
  }

  .input {
    width: 25rem;
    margin-top: 1.25rem;
    background-color: transparent;

    input,
    .input-line {
      background-color: transparent;
    }

    input {
      color: $light-grey;
    }
  }

  a {
    margin: 1.25rem auto 1.25rem 0;

    &:hover {
      color: $placeholder-color;
    }
  }

  .history {
    @include viewer-controls-scrollbar;

    overflow-y: scroll;
    flex: 1;

    .history-item {
      display: block;

      &:first-child {
        margin-top: 1rem;
      }

      &.error {
        color: $bright-red;
        text-align: center;
      }

      &.localuserrequest {
        text-align: right;
        padding-right: 0.5rem;
        color: $white;
      }

      &.otherrequest {
        text-align: right;
        padding-right: 0.5rem;
      }

      &.response {
        text-align: left;
      }
    }
  }

  > .svg-icon {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    width: $icon-size-std;
    height: $icon-size-std;
  }
}
