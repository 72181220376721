.terms-and-conditions.page {
  .nav-bar,
  .content,
  .footer {
    min-width: 80rem;
  }
}

.terms-and-conditions.page .content {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 3rem 15% 5rem;
  box-sizing: border-box;

  .page-title {
    display: block;
    margin-bottom: 1.5rem;
  }

  .terms-and-conditions-list {
    margin-top: 2rem;
    margin-bottom: 4rem;

    .card-container {
      width: 100%;
      display: block;
      margin-bottom: 1.5rem;

      .card-body {
        padding: 1.25rem;
      }
    }
  }
}
