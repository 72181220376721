.error-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;

  .h2 {
    margin: 1rem;
  }

  .button {
    margin: 2rem 1rem;
  }
}
