@import '@zaber/react-library';
@import '@zaber/virtual-device-display';

@import '../navigation_bar/NavigationBar.scss';
@import '../virtual_device/VirtualDevice.scss';
@import '../virtual_device/VirtualDeviceSelect.scss';
@import '../virtual_device_landing_page/VirtualDeviceLandingPage.scss';
@import '../virtual_device_release_notes/VirtualDeviceReleaseNotes.scss';
@import '../virtual_device_viewer/VirtualDeviceViewer.scss';
@import '../virtual_device_manager/VirtualDeviceManager.scss';
@import '../user/User.scss';
@import '../user/Account.scss';
@import '../units/Units.scss';
@import '../delete_account/DeleteAccount.scss';
@import '../zaber_launcher_download_page/ZaberLauncherDownloadPage.scss';
@import '../zaber_launcher_tutorials/ZaberLauncherTutorials.scss';
@import '../zaber_launcher_offline_page/ZaberLauncherOfflinePage.scss';
@import '../privacy_policy/PrivacyPolicy.scss';
@import '../terms_and_conditions/TermsAndConditionsPage.scss';
@import '../user_access_token/AccessTokensPage.scss';
@import '../terminal/Terminal.scss';
@import '../feedback/feedback.scss';
@import '../errors/ErrorReport.scss';
@import '../demo';
@import '../zml';
@import '../not_found';

@import '../components/IconMessage.scss';
@import '../components/TickBulletPoint.scss';
@import '../components/Banner.scss';
@import '../components/Footer.scss';
@import '../components/IconMessage.scss';
@import '../components/InputValidation.scss';
@import '../components/Survey.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// USE THIS CLASS ON THE ROOT ELEMENT OF ALL NAVIGABLE PAGES
// It provides some consistent styling and functionality that is common across
// most pages. It also makes sure to handle any content overflows so that
// they do not have to handled by any parent components (otherwise, the scroll
// position may get shared across pages that do not have this protection).
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  > .content {
    flex: 1;
    background-color: $zaber-white;
  }
}

div:focus {
  outline: none;
}

.ol-large-numbers {
  list-style: none;
  counter-reset: list-counter;

  li {
    counter-increment: list-counter;
    display: flex;
    align-items: center;
    line-height: 2rem;

    &::before {
      content: counter(list-counter) '.';
      color: $light-grey;
      font-size: 3.75rem;
      min-width: 5rem;
      font-weight: bold;
      line-height: normal;
    }
  }
}
