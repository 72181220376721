.virtual-device-product-select {
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  grid-template-rows: auto auto;
  row-gap: 0;
  column-gap: 0.5rem;

  .text-body-xsmall {
    margin-bottom: 0.125rem;
  }

  .virtual-device-select {
    width: 12rem;
  }

  .select-item-multiline {
    display: flex;
    flex-direction: column;
  }
}

.virtual-device-simulation-select {
  display: flex;
  flex-wrap: nowrap;
  width: min-content;

  .virtual-device-select {
    width: 28rem;
  }
}

.virtual-device-select {
  .vd__single-value { // stylelint-disable selector-class-pattern
    width: 100%;

    .new-window-icon,
    .active-icon,
    .inactive-icon {
      display: none;
    }
  }

  .vd__control {
    .select-item-multiline {
      .description {
        display: none;
      }
    }
  }

  .vd__option {
    height: 3.75rem;
    display: flex;
    align-items: center;
  }

  .option-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .icon-container {
      display: flex;
      align-items: center;

      .active-icon {
        font-size: 3rem;
        color: $green;
        margin-right: 2rem;
      }

      .inactive-icon {
        font-size: 3rem;
        color: $light-grey;
        margin-right: 2rem;
      }

      .new-window-icon {
        font-size: 2rem;
        color: $light-grey;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .vd__option.vd__option--is-selected {
    .text-body,
    .new-window-icon,
    .inactive-icon {
      color: $zaber-grey;
    }
  }

  .vd__indicators {
    height: 100%;
  }
}
