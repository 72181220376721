@use 'sass:math';

.tick-bullet {
  $icon-size: 2.25rem;
  $line-height: 1.3rem;

  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  width: 100%;

  .tick-icon {
    color: $green;
    font-size: $icon-size;
  }

  .tick-bullet-content {
    line-height: 1.3rem;
    margin-left: 1.25rem;
    margin-top: math.div(($icon-size - $line-height), 2);
  }
}
