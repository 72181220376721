@import './VideoPlayer.scss';

.zaber-launcher-tutorials.page {
  .content,
  .nav-bar {
    min-width: 80rem;
  }

  .markdown {
    max-width: 80rem;
    margin: 0 auto;
  }
}

.zaber-launcher-tutorials.page .content {
  .section-title {
    color: $orange;
    letter-spacing: 1px;
  }

  .banner {
    width: 38rem;

    .regular {
      font-size: 1.5rem;
      font-weight: 100;
      line-height: 2rem;
      color: $zaber-grey;
    }
  }

  .intro-container,
  .overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .intro-container {
    background-color: $zaber-white;
  }

  .intro-section {
    width: 80rem;
    background-color: $zaber-white;
    padding-top: 3rem;
    padding-bottom: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .h1 {
      display: block;
      margin-bottom: 2.5rem;
    }
  }

  .overview-container {
    background-color: $pale-grey;
  }

  .overview-section {
    width: 72rem;
    background-color: $pale-grey;
    padding: 0.75rem 0 2.5rem 8rem;
    position: relative;
    height: 21.5rem;

    .section-title,
    .overview-image {
      position: absolute;
    }

    .section-title {
      top: -2rem;
      left: 8rem;
    }

    .link-always:not(:hover) {
      color: $grey;
    }
  }

  .overview-description {
    width: 28rem;
    line-height: 1.5rem;
  }

  .overview-image {
    top: -2.5rem;
    left: 50%;

    img {
      width: 95%;
      box-shadow: 5px 5px 10px #00000029;
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }

    li::before {
      content: '\2022';
      color: $light-grey;
      font-weight: bold;
      font-size: 2rem;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  .copyright {
    margin-top: 8rem;
  }

  .raspberrypi-logo {
    margin: -0.25rem;
  }
}
