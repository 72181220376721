.zaber-launcher-offline.page {
  .content {
    padding: 0 1rem 2rem;

    .text-span {
      display: block;
      margin: 1rem 0;
    }

    .h2 {
      width: fit-content;
      margin: 2rem auto 1rem;
    }

    a {
      display: block;
      margin: 1rem 0 0 1rem;
    }
  }
}
