.input-validation {
  display: flex;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;

  .validation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;

    background-color: $almost-white;
    color: $light-grey;

    border-radius: 5rem;

    svg {
      font-size: 1.75rem;
    }

    &.valid {
      background-color: rgba($green, 0.15);
      color: $zaber-grey;

      svg {
        color: $green;
      }
    }

    &.invalid {
      background-color: $zaber-red;
      color: $white;
    }
  }
}
