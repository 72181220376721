.delete-acccount.page >.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .page-title {
    max-width: 84rem;
    width: 100%;
    display: block;
    padding: 0 2rem;
    margin: 1.75rem 0;
    box-sizing: border-box;
  }

  .delete-account-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .farewell {
      display: block;
      margin-top: 5rem;
    }

    .keep-account {
      margin-top: 0.5rem;
      text-decoration: underline;
      text-decoration-color: $light-grey;

      :hover {
        cursor: pointer;
      }
    }

    .container {
      margin-top: 2.75rem;
      padding: 0.25rem;

      .password {
        margin-top: 0.75rem;
        margin-bottom: 2.75rem;
        display: flex;
        align-items: center;

        .input-line {
          width: 24.5rem;
          height: 2.25rem;
          display: flex;
          align-items: center;
        }

        .password-error >.input-line {
          border-color: $error-fault;
          border-width: 1.5px;
        }

        .button {
          &.red {
            margin-left: 3rem;
          }
        }

        .status {
          margin-bottom: 3.25rem;
          width: 100%;
        }
      }

      .error-message-container {
        margin-top: 0.25rem;
      }

      .feedback {
        width: 44.75rem;
        display: block;
      }

      .feedback-button {
        display: block;
        margin-top: 1.25rem;
        margin-bottom: 3.25rem;
        margin-left: auto;
        margin-right: 0;
      }

      textarea {
        width: 44.75rem;
        height: 10rem;
        margin-top: 0.75rem;
      }
    }

    .feedback-submitted {
      display: flex;
      justify-content: center;
      margin-top: 3.25rem;
      color: $green;
      font-size: 1.25rem;
    }
  }
}

.delete-account-info {
  padding: 1.5rem;

  .warning {
    display: block;
    margin-bottom: 2rem;
  }

  .warning-list-content {
    padding-left: 1.5rem;
    margin-top: 1rem;
    list-style: disc;
  }

  .buttons {
    margin: 1rem auto 1.5rem;
  }

  .delete-account-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;
  }
}
