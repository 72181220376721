@mixin dark-blue-blur {
  background-color: rgba($dark-blue, 0.75);
  backdrop-filter: blur(15px);
}

@mixin viewer-controls-scrollbar {
  $thumb-color: $light-grey;
  $thumb-color-hover: $white;
  $bg-color: transparent;

  /* For firefox */
  scrollbar-color: $thumb-color $bg-color;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }
      
  &::-webkit-scrollbar-track {
    background: $bg-color;
  }
      
  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
  }
      
  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-color-hover;
  }
}
