.demo {
  padding: 1rem;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 1rem;
  }

  .axis {
    .contents {
      padding: 0.5rem;
      display: flex;
      align-items: center;
    }

    .svg-icon {
      margin-right: 0.25rem;
    }
  }

  .explainer {
    margin-top: 1rem;
  }

  .loading {
    align-self: center;
  }

  .spacer {
    flex: 1;
  }
}
