.simulation-on-off-toggle {
  display: flex;
  align-items: center;

  .on-off-toggle {
    padding: 0.25rem;
    font-size: 1.5rem;
  }

  .green {
    color: $green;
  }

  svg.disabled {
    cursor: unset;
  }

  span.disabled {
    color: $light-grey;
  }

  .status {
    padding-left: 0.25rem;
  }

  .error-popup {
    .pop-up-content {
      width: 25rem;
      text-align: left;
    }

    .h5 {
      display: block;
      margin-bottom: 0.5rem;
    }

    .refresh-message {
      display: block;
      margin-top: 0.5rem;
    }
  }

  .error-toggle {
    position: relative;
    display: inline-block;

    .error-dot,
    .info-dot {
      color: $zaber-red;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 1.25rem;
    }

    .info-dot {
      color: $orange;
    }
  }
}

.virtual-device-id {
  $width: 8rem;

  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;

  &.copied {
    width: $width;
  }

  &.disabled {
    color: $light-grey;
  }

  &:not(.copied, .disabled):hover {
    cursor: pointer;
    font-weight: bold;
  }
}
