.value-with-units {
  color: #808080;
  pointer-events: all;
  display: inline-block;

  .unit-select {
    border: 0;
    outline: none;
    background-color: transparent;
    color: #808080;
  }
}
