@use 'sass:math';

.virtual-device-landing.page {
  .nav-bar,
  .footer {
    min-width: 84rem;
  }

  .footer {
    padding-top: 8rem;
  } 
}

.virtual-device-landing.page .content {
  $content-width: 80rem;

  min-width: $content-width + 4rem;
  background-color: $zaber-white;

  .section {
    &.dark {
      background-color: $almost-white;
    }

    .section-title {
      color: $orange;
      letter-spacing: 1px;
      display: block;
      width: $content-width;
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    .section-content {
      width: $content-width;
      margin: 0 auto;
    }
  }

  .dark-section {
    background-color: $almost-white;
  }

  .banner .regular {
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 2rem;
    color: $zaber-grey;
  }

  .introduction {
    .section-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .h1 {
      margin: 3rem 0 1rem;
    }

    .pop-up {
      .pop-up-content {
        width: 40rem;
        text-align: left;
      }

      .badge-container {
        display: flex;
        gap: 1rem;
        align-items: center;

        .badge {
          display: inline-block;
          padding: 0.25rem 0.5rem;
          background-color: $light-grey;
          color: $white;
          border-radius: 0.25rem;
        }

        svg {
          font-size: 2.5rem;
          color: $light-grey;
        }
      }
    }

    .banner {
      width: 39rem;
      margin-top: 3rem;
    }
  }

  .overview {
    width: 100%;
    margin: 8rem 0;
    position: relative;

    .section-title {
      position: absolute;
      left: 0;
      right: 0;
      top: -2.5rem;
    }

    .section-content {
      display: flex;
      justify-content: center;
      gap: 2rem;

      .text-container,
      .media-container {
        flex: 1;
      }

      .text-container {
        padding: 3rem 0;
      }

      .media-container {
        position: relative;

        img {
          width: 100%;
          position: absolute;
          box-shadow: 5px 5px 10px #00000029;
          top: -5rem;
        }
      }
    }
  }

  .vd-benefits {
    margin-bottom: 5rem;

    .banner {
      width: 40rem;
      margin: 0 auto;
      margin-top: 2rem;
    }

    .benefit-points {
      width: $content-width;
      margin: 6rem auto 0;
      display: flex;
      justify-content: space-evenly;

      .benefit {
        width: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        .icon {
          $size: 5rem;

          width: $size;
          height: $size;
        }
      }
    }
  }

  .software {
    padding: 2.5rem 0;

    .section-content {
      display: flex;
      justify-content: space-evenly;
      width: $content-width;
      margin: 0 auto;

      .software-item {
        $icon-height: 2.5rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 27.5rem;

        .h4 {
          margin: 1rem 0;
        }

        .sub-heading {
          margin-bottom: 1rem;
        }

        .link-buttons {
          display: flex;
          gap: 1rem;
          flex: 1;
          padding-top: 3rem;
          align-items: flex-end;
        }

        &.zaber-launcher img {
          height: $icon-height;
          border-radius: 0.5rem;
        }

        &.zml img {
          height: $icon-height;
        }
      }
    }
  }

  .try-for-free {
    .section-title {
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
    }

    .section-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .select-controls {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
    }

    .info-container {
      width: 20rem;
    }

    .no-private-sim-message {
      padding-bottom: 1rem;

      svg {
        color: $error-note;
      }
    }
  }

  .how-it-works {
    padding-bottom: 10rem;

    .section-title {
      margin-top: 3.5rem;
      margin-bottom: 3.5rem;
    }

    .section-content {
      display: flex;
      justify-content: center;
      gap: 1rem;

      .text-container,
      .media-container {
        flex: 1;
      }

      .media-container {
        position: relative;
        margin-top: -5rem;

        img {
          box-shadow: 5px 5px 10px #00000029;
        }

        .virtual-device {
          position: relative;

          img.vd-viewer {
            width: 80%;
            position: absolute;
            right: 0;
          }

          img.vd-viewer-controls {
            position: absolute;
            width: 35%;
            top: 4rem;
            left: 2rem;
            border: 1px solid $grey;
            border-radius: 0.5rem;
          }
        }

        .zaber-launcher {
          position: absolute;
          top: 14rem;
          left: 12rem;

          img.zl-terminal {
            width: 80%;
          }

          img.zl-terminal-devices {
            position: absolute;
            top: 2.5rem;
            left: -3rem;
            width: 30%;
          }

          img.zl-terminal-input {
            position: absolute;
            bottom: 2rem;
            left: 3rem;
            width: 80%;
          }
        }
      }

      .text-container .h4 {
        margin-bottom: 0.5rem;
        display: inline-block;
      }

      ol {
        list-style: none;
        counter-reset: list-counter;
      
        li {
          counter-increment: list-counter;
          display: flex;
          line-height: 1.25rem;
        
          &::before {
            content: counter(list-counter) '.';
            color: $zaber-grey;
            min-width: 1.5rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .subscription-benefits {
    padding: 3.5rem 0 1.25rem;

    .section-title {
      margin-bottom: 3.5rem;
    }

    .benefit-points {
      display: flex;
      justify-content: space-evenly;
      width: $content-width;
      margin: 0 auto 3.5rem;

      .benefit {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 9rem;

        .icon {
          $size: 5rem;

          width: $size;
          height: $size;
          margin-bottom: 1rem;
        }

        > span {
          text-align: center;
        }
      }
    }

    .info {
      display: block;
      text-align: center;
    }
  }

  .sign-up {
    .section-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 3.5rem;
    }

    .h2 {
      margin-bottom: 3.5rem;
    }

    .banner {
      width: 40rem;
      margin-bottom: 3.5rem;
    }

    button {
      margin-top: 2rem;
    }
  }
}
