.login.page,
.password-reset.page,
.sign-up.page {
  .nav-bar,
  .content,
  .footer {
    min-width: 80rem;
  }

  .content {
    .h1 {
      margin: 4.5rem 0 4rem;
      display: inline-block;
    }

    .input-container {
      width: 26.5rem;
    }

    .info {
      max-width: 37.5rem;

      &.centered {
        text-align: center;
      }

      b {
        font-weight: bold;
      }
    }
  }
}

.login.page>.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input-container .input {
    margin-bottom: 1rem;
  }

  .login-options {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    .input-checkbox {
      flex: 1;
    }
  }

  .sign-in-button {
    margin-bottom: 1.25rem;
  }

  .notice-message {
    margin-bottom: 1rem;
  }

  .info>svg {
    color: $error-note;
  }

  .resend-email {
    .h4 {
      display: block;
      margin-bottom: 1rem;
    }

    .button {
      margin: 1rem 0;
      margin-left: auto;
      display: block;
    }
  }
}

.password-reset.page>.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error {
    color: $error-fault;
    margin-bottom: 1rem;
  }

  svg.in-progress {
    height: 3.5rem;
    margin: 2rem;
  }

  .input-container {
    margin-bottom: 2rem;

    .input {
      margin-bottom: 1rem;
    }

    .input-validation {
      margin: -0.5rem 0 1rem;
    }
  }

  button {
    margin-bottom: 1.25rem;
  }
}

.sign-up.page>.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.normal {
    .h1 {
      margin-bottom: 1.75rem;
    }

    .input-container {
      .input {
        margin-bottom: 1rem;
      }

      .input-validation {
        margin: -0.5rem 0 1rem;
      }
    }

    button {
      margin: 2.5rem 0 1.25rem;
    }

    .error {
      color: $error-fault;
      margin-bottom: 2rem;
    }
  }

  &.success {
    .email-sent {
      color: $green;
      padding-bottom: 3.5rem;
    }

    .did-not-get-email {
      margin: 4rem 0 0.5rem;
    }
  }
}
