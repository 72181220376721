.nav-bar {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 5rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  justify-content: flex-end;

  @include screen-below('s') {
    &.responsive {
      flex-direction: column;
      min-height: unset;
      padding: 0.5rem;

      .menu-expand {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2rem;
      }

      .title {
        flex-direction: column;
        margin: 0.5rem;
      }

      .options-container {
        min-width: calc(100% - 4rem);
        border-radius: 0.5rem;

        .option {
          text-align: center;
        }
      }

      &.collapsed {
        > *:not(.title) {
          display: none;
        }
      }

      &:not(.collapsed) {
        .title {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .menu-expand {
    display: none;
  }

  .title {
    flex: 1;
    margin: 0.5rem 2.5rem;
    display: flex;
    align-items: center;
    position: relative;

    a {
      display: flex;

      > svg {
        height: 2rem;
      }
    }
  }

  .main-button {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .signed-in-user {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      font-size: 2.5rem;
      color: $light-grey;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $pale-grey;
    margin: 0.5rem 0;
  }
}

.navigation-group {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 3.25rem;

  .group-title {
    padding: 1rem 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;

    &.highlighted {
      color: $orange;
    }
  }

  .options-container {
    min-width: 100%;
    padding: 0.5rem 0;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 0.5rem 0.5rem;
    position: absolute;
    top: 100%;
    z-index: 1;

    &.hidden {
      display: none;
    }

    .option {
      cursor: pointer;
      padding: 0.5rem 1.25rem;
      white-space: nowrap;

      &.highlighted {
        background-color: $pale-grey;
      }

      &:hover {
        background-color: $almost-white;
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
  }
}
