@use 'sass:math';

.icon-message-container {
  $icon-size: 1.5rem;
  $line-height: 1.3rem;
  $small-icon-size: 1.25rem;
  $helper-line-height: 1rem;
  $margin-size: 0.5rem;

  display: flex;

  > svg {
    font-size: $icon-size;
    margin: $margin-size 0;
  }

  .message {
    flex: 1;
    display: flex;
    align-items: center;
    margin: $margin-size;
    margin-top: calc((#{$icon-size} - #{$line-height}) / 2 + #{$margin-size});
  }

  &.small {
    svg {
      font-size: $small-icon-size;
    }

    .message {
      margin-top: calc((#{$small-icon-size} - #{$helper-line-height}) / 2 + #{$margin-size});
    }
  }
}
