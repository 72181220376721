.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $zaber-white;

  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 3rem;

    .separator {
      height: 1.5rem;
      width: 1px;
      background-color: $light-grey;
      margin: 0 1.25rem;
    }
  }

  > span {
    padding-bottom: 1.5rem;
  }
}
