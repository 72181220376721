.virtual-device-release-notes.page {
  .nav-bar,
  .content,
  .footer {
    min-width: 80rem;
  }
}

.virtual-device-release-notes.page .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;

  .page-title,
  .card-container {
    box-sizing: border-box;
    padding: 0 8rem;
    width: 100%;
  }

  .page-title {
    display: block;
    margin-top: 2rem;
  }

  .banner {
    margin-top: 1.5rem;
  }

  .banner .regular {
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 2rem;
    color: $zaber-grey;
  }

  .card-title {
    padding: 2rem 0;
    display: flex;
    align-items: center;

    .chevron {
      margin-right: 1rem;
    }
  }

  .card {
    margin-bottom: 2rem;

    .contents {
      padding: 1.5rem;

      ol {
        list-style: decimal;
      }

      ul {
        list-style: disc;
      }

      ol,
      ul {
        padding: 0 1.5rem;

        li {
          margin: 0.5rem 0;
        }
      }
    }
  }
}
